import React, { Component } from 'react';
import './FMembers.css';
import { Container, Card, Button, ListGroup } from 'react-bootstrap';
import * as Profiles from '../Pages/Images/Profiles/ProfilesLoader';

export default class Members extends Component {
    render() {
        return (
            <>
                <div className="about-layout d-md-flex flex-md-column align-items-center">
                    <Container className="container-about container-members-title d-md-flex flex-md-column mr-auto">
                        <h2>Наши специалисты</h2>
                    </Container>

                    <Container className="container-members-cards gap-3 d-flex justify-content-center flex-wrap">
                        {[
                            {
                                name: 'Сабадаш Андрей Владимирович',
                                role: 'Главный врач',
                                details: ['Врач-хирург 1 категории', 'Врач УЗИ'],
                                reviewsLink: 'https://prodoctorov.ru/ekaterinburg/vrach/495857-sabadash/',
                                educationLink: 'https://prodoctorov.ru/ekaterinburg/vrach/495857-sabadash/#educations',
                                image: Profiles.profile0,
                            },
                            {
                                name: 'Шушанов Антон Павлович',
                                role: 'Заведующий хирургическим отделением',
                                details: ['Врач-хирург Высшей категории', 'Врач ультразвуковой диагностики', 'Онколог'],
                                reviewsLink: 'https://prodoctorov.ru/ekaterinburg/vrach/229097-shushanov/',
                                educationLink: 'https://prodoctorov.ru/ekaterinburg/vrach/229097-shushanov/#educations',
                                image: Profiles.profile1,
                            },
                            {
                                name: 'Липин Глеб Игоревич',
                                role: '',
                                details: ['Врач-хирург', 'Травматолог-ортопед'],
                                reviewsLink: 'https://prodoctorov.ru/ekaterinburg/vrach/989884-lipin/',
                                educationLink: 'https://prodoctorov.ru/ekaterinburg/vrach/989884-lipin/#educations',
                                image: Profiles.profile3,
                            },
                            {
                                name: 'Дадашев Малик Рафикович',
                                role: '',
                                details: ['Врач-хирург'],
                                reviewsLink: 'https://prodoctorov.ru/ekaterinburg/vrach/971760-dadashev/',
                                educationLink: 'https://prodoctorov.ru/ekaterinburg/vrach/971760-dadashev/#educations',
                                image: Profiles.profile6,
                            },
                            {
                                name: 'Камилов Магомед Юсупович',
                                role: '',
                                details: ['Врач-хирург', 'Колопроктолог'],
                                reviewsLink: 'https://prodoctorov.ru/ekaterinburg/vrach/735784-kamilov/',
                                educationLink: 'https://prodoctorov.ru/ekaterinburg/vrach/735784-kamilov/#educations',
                                image: Profiles.profile2,
                            },
                            {
                                name: 'Гилева Елена Александровна',
                                role: 'Старшая медицинская сестра',
                                details: [],
                                image: Profiles.profile4,
                            },
                            {
                                name: 'Белкина Полина',
                                role: 'Медицинская сестра',
                                details: [],
                                image: Profiles.profile7,
                            },
                            {
                                name: 'Анна',
                                role: 'Администратор',
                                details: [],
                                image: Profiles.profile8,
                            },
                            
                        ].map((profile, index) => (
                            <Card key={index} style={{ width: '19rem' }} className="member-card">
                                <Card.Img variant="top" src={profile.image} className="card-img-top" />
                                <Card.Body className="card-body">
                                    <Card.Title>{profile.name}</Card.Title>
                                    {profile.role && <Card.Text>{profile.role}</Card.Text>}
                                    {profile.details.length > 0 && (
                                        <ListGroup className="list-group-flush">
                                            {profile.details.map((detail, i) => (
                                                <ListGroup.Item key={i}>{detail}</ListGroup.Item>
                                            ))}
                                        </ListGroup>
                                    )}
                                </Card.Body>
                                {(profile.reviewsLink || profile.educationLink) && (
                                    <Card.Footer className="card-footer">
                                        <Container className="container-members-buttons d-md-flex flex-md-column mr-auto gap-3 py-3">
                                            {profile.reviewsLink && (
                                                <Button variant="primary" href={profile.reviewsLink}>
                                                    Отзывы
                                                </Button>
                                            )}
                                            {profile.educationLink && (
                                                <Button variant="primary" href={profile.educationLink}>
                                                    Образование
                                                </Button>
                                            )}
                                        </Container>
                                    </Card.Footer>
                                )}
                            </Card>
                        ))}
                    </Container>
                </div>
            </>
        );
    }
}