import React, { useState, useRef, useCallback, useEffect } from "react";
import { Container, Modal, Button, Badge } from "react-bootstrap";
import ReactPlayer from "react-player";
import "./FGallery.css";
import { LazyLoadImage } from "react-lazy-load-image-component";
import 'react-lazy-load-image-component/src/effects/blur.css';

const images = [
  {
    src: require("../Pages/Images/News/news44.jpg"),
    alt: "Команда Хирург и Я",
    type: "image",
  },
  {
    src: require("../Pages/Images/News/news45.jpg"),
    alt: "Команда Хирург и Я",
    type: "image",
  },
  {
    src: require("../Pages/Images/gal27.jpg"),
    alt: "Хирург и Я фотосессия",
    type: "image",
  },
  {
    src: require("../Pages/Images/gal26.jpg"),
    alt: "Хирург и Я фотосессия",
    type: "image",
  },
  {
    src: require("../Pages/Images/News/news38.jpg"),
    alt: "Мейлах Борис Львович и Сабадаш Андрей Владимирович",
    type: "image",
  },
  {
    src: require("../Pages/Images/News/news37.jpg"),
    alt: "Шушанов Антон Павлович и Сабадаш Андрей Владимирович",
    type: "image",
  },
  {
    src: require("../Pages/Images/gal22.jpg"),
    alt: "Сабадаш Андрей Владимирович и Камилов Магомед Юсупович",
    type: "image",
  },
  {
    src: require("../Pages/Images/gal23.jpg"),
    alt: "Шушанов Антон Павлович и Сабадаш Андрей Владимирович",
    type: "image",
  },
  {
    src: require("../Pages/Images/gal24.jpg"),
    alt: "Шушанов Антон Павлович и Сабадаш Андрей Владимирович",
    type: "image",
  },
  {
    src: require("../Pages/Images/gal25.jpg"),
    alt: "Сабадаш Андрей Владимирович и администратор Анна",
    type: "image",
  },
  {
    src: require("../Pages/Images/gal28.jpg"),
    alt: "Хирург и Я фотосессия",
    type: "image",
  },
  {
    src: require("../Pages/Images/News/snews8.jpg"),
    alt: "YCELLBIO-KIT",
    type: "image",
  },
  {
    src: require("../Pages/Images/News/news35.jpg"),
    alt: "Сабадаш Андрей Владимирович и администратор Анна",
    type: "image",
  },
  {
    src: require("../Pages/Images/News/snews7.mp4"),
    type: "video",
    alt: "Video 4",
    poster: require("../Pages/Images/News/snews7.jpg"),
    badge: "Видео",
  },
  {
    src: require("../Pages/Images/News/snews6.jpg"),
    alt: "Image 28",
    type: "image",
  },
  {
    src: require("../Pages/Images/News/news33.jpg"),
    alt: "Image 27",
    type: "image",
  },
  {
    src: require("../Pages/Images/News/news32.jpg"),
    alt: "Image 26",
    type: "image",
  },
  {
    src: require("../Pages/Images/News/news31.jpg"),
    alt: "Image 25",
    type: "image",
  },
  {
    src: require("../Pages/Images/News/news25.jpg"),
    alt: "Image 20",
    type: "image",
  },
  {
    src: require("../Pages/Images/News/news27.jpg"),
    alt: "Image 22",
    type: "image",
  },
  {
    src: require("../Pages/Images/News/news28.jpg"),
    alt: "Image 23",
    type: "image",
  },
  {
    src: require("../Pages/Images/News/news29.jpg"),
    alt: "Image 23",
    type: "image",
  },
  {
    src: require("../Pages/Images/News/news30.jpg"),
    alt: "Image 24",
    type: "image",
  },
  {
    src: require("../Pages/Videos/galvideo3.mp4"),
    type: "video",
    alt: "Video 3",
    poster: require("../Pages/Images/videoposter3.png"),
    badge: "Видео",
  },
  {
    src: require("../Pages/Images/gal21.jpg"),
    alt: "Image 21",
    type: "image",
  },
  {
    src: require("../Pages/Videos/galvideo1.mp4"),
    type: "video",
    alt: "Хирург и Я вид с улицы видео",
    poster: require("../Pages/Images/videoposter1.png"),
    badge: "Видео",
  },
  {
    src: require("../Pages/Images/News/news40.jpg"),
    alt: "Хирург и Я препараты",
    type: "image",
  },
  {
    src: require("../Pages/Images/gal20.jpg"),
    alt: "Image 3",
    type: "image",
  },
  {
    src: require("../Pages/Images/gal4.jpg"),
    alt: "Хирург и Я команда",
    type: "image",
  },
  {
    src: require("../Pages/Videos/galvideo2.mp4"),
    type: "video",
    alt: "Хирург и Я видео операции",
    poster: require("../Pages/Images/videoposter2.png"),
    badge: "Видео",
  },
  {
    src: require("../Pages/Images/gal5.jpg"),
    alt: "Хирург и Я вид с улицы",
    type: "image",
  },
  {
    src: require("../Pages/Images/gal6.jpg"),
    alt: "Image 6",
    type: "image",
  },
  {
    src: require("../Pages/Images/gal7.jpg"),
    alt: "Image 7",
    type: "image",
  },
  {
    src: require("../Pages/Images/gal8.jpg"),
    alt: "Хирург и Я приемная",
    type: "image",
  },
  {
    src: require("../Pages/Images/gal9.jpg"),
    alt: "Сабадаш Андрей Владимирович",
    type: "image",
  },
  {
    src: require("../Pages/Images/gal10.jpg"),
    alt: "Сабадаш Андрей Владимирович интервью",
    type: "image",
  },
  {
    src: require("../Pages/Images/gal13.jpg"),
    alt: "Хирург и Я аптечка",
    type: "image",
  },
  {
    src: require("../Pages/Images/gal14.jpg"),
    alt: "Хирург и Я зал",
    type: "image",
  },
  {
    src: require("../Pages/Images/gal16.jpg"),
    alt: "Хирург и Я кабинет",
    type: "image",
  },
  {
    src: require("../Pages/Images/gal17.jpg"),
    alt: "Хирург и Я",
    type: "image",
  },
  {
    src: require("../Pages/Images/gal18.jpg"),
    alt: "Хирург и Я",
    type: "image",
  },
  {
    src: require("../Pages/Images/gal19.jpg"),
    alt: "Благодарность Хирург и Я",
    type: "image",
  },
];

export default function FGallery() {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [showModal, setShowModal] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(12); // Количество элементов на странице
  const carouselRef = useRef(null);

  // Открытие модального окна
  const openModal = (index) => {
    setCurrentIndex(index);
    setShowModal(true);
  };

  // Закрытие модального окна
  const closeModal = () => {
    setShowModal(false);
  };

  // Переход к следующему элементу в модальном окне
  const handleNext = useCallback(() => {
    const nextIndex = (currentIndex + 1) % images.length;
    setCurrentIndex(nextIndex);
  }, [currentIndex]);

  // Переход к предыдущему элементу в модальном окне
  const handlePrevious = useCallback(() => {
    const prevIndex = (currentIndex - 1 + images.length) % images.length;
    setCurrentIndex(prevIndex);
  }, [currentIndex]);

  // Обработка нажатий клавиш в модальном окне
  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === "ArrowRight") {
        handleNext();
      } else if (event.key === "ArrowLeft") {
        handlePrevious();
      }
    };

    window.addEventListener("keydown", handleKeyDown);
    return () => window.removeEventListener("keydown", handleKeyDown);
  }, [handleNext, handlePrevious]);

  // Логика пагинации
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = images.slice(indexOfFirstItem, indexOfLastItem);

  // Смена страницы
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  // Генерация номеров страниц
  const generatePageNumbers = (totalPages) => {
    const pages = [];
    const maxVisible = 5; // Максимум видимых кнопок

    if (totalPages <= maxVisible) {
      for (let i = 1; i <= totalPages; i++) pages.push(i);
    } else {
      pages.push(1);

      if (currentPage > 3) pages.push("...");

      const start = Math.max(2, currentPage - 1);
      const end = Math.min(totalPages - 1, currentPage + 1);

      for (let i = start; i <= end; i++) pages.push(i);

      if (currentPage < totalPages - 2) pages.push("...");

      pages.push(totalPages);
    }

    return pages;
  };

  const totalPages = Math.ceil(images.length / itemsPerPage);
  const pageNumbers = generatePageNumbers(totalPages);

  return (
    <>
      <div className="gallery" ref={carouselRef}>
        <Container className="container-gallery-layout mt-5">
          <div className="row gy-4">
            {currentItems.map((image, index) => (
              <div key={index} className="col-md-3 position-relative">
                {image.type === "image" ? (
                  <LazyLoadImage
                    src={image.src}
                    alt={image.alt}
                    effect="blur"
                    className="img-fluid rounded-lg rounded gallery-image"
                    placeholderSrc="https://via.placeholder.com/300x300"
                    onClick={() => openModal(index + (currentPage - 1) * itemsPerPage)}
                  />
                ) : (
                  <img
                    src={image.poster}
                    alt={image.alt}
                    className="img-fluid rounded-lg rounded gallery-image"
                    onClick={() => openModal(index + (currentPage - 1) * itemsPerPage)}
                  />
                )}
                {image.badge && (
                  <Badge className="gallery-image-badge position-absolute">
                    {image.badge}
                  </Badge>
                )}
              </div>
            ))}
          </div>
        </Container>

        {/* Пагинация */}
        <div style={{
          display: 'flex',
          justifyContent: 'center',
          gap: '8px',
          flexWrap: 'wrap',
          margin: '20px 0',
          padding: '0 16px'
        }}>
          <button
            onClick={() => handlePageChange(currentPage - 1)}
            disabled={currentPage === 1}
            style={paginationButtonStyle}
          >
            Назад
          </button>

          {pageNumbers.map((number, index) => (
            number === '...' ? (
              <span key={index} style={{ padding: '8px' }}>...</span>
            ) : (
              <button
                key={index}
                onClick={() => handlePageChange(number)}
                style={{
                  ...paginationButtonStyle,
                  backgroundColor: currentPage === number ? '#007bff' : '#f8f9fa',
                  color: currentPage === number ? 'white' : '#007bff',
                  minWidth: '36px'
                }}
              >
                {number}
              </button>
            )
          ))}

          <button
            onClick={() => handlePageChange(currentPage + 1)}
            disabled={currentPage === totalPages}
            style={paginationButtonStyle}
          >
            Вперед
          </button>
        </div>
      </div>

      {/* Модальное окно */}
      <Modal show={showModal} onHide={closeModal} centered>
        <Modal.Body>
          {images[currentIndex] && (
            images[currentIndex].type === "video" ? (
              <ReactPlayer url={images[currentIndex].src} controls width="100%" height="100%" />
            ) : (
              <img
                src={images[currentIndex].src}
                alt={images[currentIndex].alt}
                className="img-fluid"
              />
            )
          )}
        </Modal.Body>
        <Modal.Footer>
          <div className="gallery-controls">
            <Button variant="primary" className="me-2" onClick={handlePrevious}>
              Предыдущее
            </Button>
            <Button variant="primary" onClick={handleNext}>
              Следующее
            </Button>
          </div>
          <Button variant="secondary" onClick={closeModal}>
            Закрыть
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

// Стили для кнопок пагинации
const paginationButtonStyle = {
  padding: '8px 12px',
  border: '1px solid #dee2e6',
  borderRadius: '8px',
  cursor: 'pointer',
  transition: 'all 0.3s',
  backgroundColor: '#f8f9fa',
  color: '#007bff',
  fontSize: '14px',
  ':hover': {
    backgroundColor: '#e9ecef'
  },
  ':disabled': {
    opacity: '0.6',
    cursor: 'not-allowed'
  }
};