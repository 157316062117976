import React, { useState, useEffect } from "react";
import "./FFeatures.css";
import { Container, Row, Col } from "react-bootstrap";
import icon from "./icon.svg";

export default function FFeatures() {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const isRowEnabled = windowWidth <= 425;

  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const features = [
    {
      title: "Профессионализм",
      description:
        "Мы подобрали настоящую команду профессионалов - главным преимуществом Клиники 'Хирург и Я' является персонал!",
    },
    {
      title: "Доступные цены",
      description:
        "Мы предлагаем высокое качество лечения по разумной и доступной цене.",
    },
    {
      title: "Качественный сервис",
      description:
        "С уважением к пациенту, с любовью к хирургии - это наш девиз! Уважительное и доброжелательное отношение к каждому пациенту, залог нашего успеха.",
    },
    {
      title: "Надежность",
      description: "Мы создали все условия для вашего скорейшего выздоровления.",
    },
  ];

  return (
    <Container className="features-container">
      <Row className="g-4">
        {features.map((feature, index) => (
          <Col key={index} md={6} className="d-flex align-items-stretch">
            <div className={`feature-card ${isRowEnabled ? "row" : ""}`}>
              <img
                src={icon}
                height="30"
                width="30"
                className="feature-icon"
                alt="FeaturesIcon"
              />
              <div className="feature-text">
                <h2>{feature.title}</h2>
                <p>{feature.description}</p>
              </div>
            </div>
          </Col>
        ))}
      </Row>
    </Container>
  );
}