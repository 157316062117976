import React from 'react';
import "./FPricing.css";
import {Button, Container} from 'react-bootstrap';
import Table from 'react-bootstrap/Table';

export default function FPricing() {
    return ( 
    <> 
    <Container className='container-prices-layout mb-5 mt-5'>
        <h2>Ниже приведены цены на наши услуги</h2>
        <p>Ценовое предложение не является публичной офертой. Всю информацию необходимо уточнять у администратора</p>
        <Table striped bordered hover>
            <thead>
                <tr>
                    <th>Наименование</th>
                    <th>Стоимость</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <th colspan="2" className='text-center'>Консультация специалиста</th>
                </tr>
                <tr>
                    <td>Первичная консультация хирурга</td>
                    <td>2000</td>
                </tr>
                <tr>
                    <td>Первичная консультация колопроктолога</td>
                    <td>2000</td>
                </tr>
                <tr>
                    <td>Первичная консультация хирурга Шушанов А.П.
                    </td>
                    <td>5000</td>
                </tr>
                <tr>
                    <td>Повторная консультация хирурга, повторный прием</td>
                    <td>1500</td>
                </tr>
                <tr>
                    <td>Повторная консультация хирурга Шушанов А.П.</td>
                    <td>3000</td>
                </tr>
                <tr>
                    <td>Повторная консультация колопроктолога, повторный прием</td>
                    <td>1500</td>
                </tr>
                <tr>
                    <td>Выезд хирурга на дом в пределах г. Екатеринбург (без учета манипуляций)</td>
                    <td>7000</td>
                </tr>
                <tr>
                    <th colspan="2" className='text-center'>Консультация считается повторной, если с даты первичной консультации прошло не более 2 месяцев.</th>
                </tr>
                <tr>
                    <th colspan="2" className='text-center'>Манипуляции. Все манипуляции проводятся на первичном или повторном приеме врача</th>
                </tr>
                <tr>
                    <td>Внутримышечная инъекция</td>
                    <td>250</td>
                </tr>
                <tr>
                    <td>Перевязка послеоперационной раны</td>
                    <td>1000</td>
                </tr>
                <tr>
                    <td>Перевязка послеоперационной раны (сложный случай)</td>
                    <td>1500</td>
                </tr>
                <tr>
                    <td>Перевязка гнойной послеоперационной раны</td>
                    <td>2000</td>
                </tr>
                <tr>
                    <td>Перевязка пролежей</td>
                    <td>3500</td>
                </tr>
                <tr>
                    <td>Перевязка пролежей + некрэктомия</td>
                    <td>5000</td>
                </tr>
                <tr>
                    <td>Перевязка поверхностей раны</td>
                    <td>1000</td>
                </tr>
                <tr>
                    <td>Перевязка глубокой раны</td>
                    <td>2000</td>
                </tr>
                <tr>
                    <td>Перевязка ожоговой раны до 10 см</td>
                    <td>2200</td>
                </tr>
                <tr>
                    <td>Перевязка ожоговой раны с признаками нагноения до 10 см</td>
                    <td>2500</td>
                </tr>
                <tr>
                    <td>Перевязка ожоговой раны со вскрытием пузырей, некрэктомией</td>
                    <td>4000</td>
                </tr>
                <tr>
                    <td>Частичное снятие швов, снятие вторично наложенных швов</td>
                    <td>500</td>
                </tr>
                <tr>
                    <td>Снятие швов послеоперационной раны</td>
                    <td>1500</td>
                </tr>
                <tr>
                    <td>Наложение вторичных швов</td>
                    <td>3000</td>
                </tr>
                <tr>
                    <td>Снятие швов (при нагноении послеоперационной раны) с последующей санацией
                        раны, некрэктомией</td>
                    <td>5000</td>
                </tr>
                <tr>
                    <td>Пункция серомы послеоперационной раны</td>
                    <td>1700</td>
                </tr>
                <tr>
                    <td>Пункция сустава</td>
                    <td>2500</td>
                </tr>
                <tr>
                    <td>УЗИ контроль (разметка)</td>
                    <td>1500</td>
                </tr>
                <tr>
                    <td>Установка трубчатого дренажа в полость послеоперационной раны</td>
                    <td>2500</td>
                </tr>
                <tr>
                    <td>Иссечение краев послеоперационной раны</td>
                    <td>5000</td>
                </tr>
                <tr>
                    <td>Установка сквозного дренажа в полость раны</td>
                    <td>3000</td>
                </tr>
                <tr>
                    <td>Переустановка/удаление дренажа в полости послеоперационных ран</td>
                    <td>200</td>
                </tr>
                <tr>
                    <td>Подшивание дренажной трубки</td>
                    <td>2500</td>
                </tr>
                <tr>
                    <td>Удаление трубчатого дренажа из раны</td>
                    <td>1500</td>
                </tr>
                <tr>
                    <td>Установка низковакуумного дренажа в полость послеоперационной раны</td>
                    <td>6000</td>
                </tr>
                <tr>
                    <td>Удаление инородного тела</td>
                    <td>6000</td>
                </tr>
                <tr>
                    <td>Восстановление холецистостомы</td>
                    <td>4000</td>
                </tr>
                <tr>
                    <td>Восстановление эпицистостомы</td>
                    <td>4000</td>
                </tr>
                <tr>
                    <td>Замена калоприемника</td>
                    <td>3500</td>
                </tr>
                <tr>
                    <td>Дополнительное использование специального перевязочного материала</td>
                    <td>500</td>
                </tr>
                <tr>
                    <td>Использование ЭХВЧ (электрохихургического высокочастотного аппарата)</td>
                    <td>3000</td>
                </tr>
                <tr>
                    <td>Вскрытие абсцессов, нагноившихся атером туловища размером до 3 см</td>
                    <td>3000</td>
                </tr>
                <tr>
                    <td>Вскрытие абсцессов, нагноившихся атером туловища размером 4 - 5 см</td>
                    <td>4000</td>
                </tr>
                <tr>
                    <td>Вскрытие абсцессов сложной локализации (голова, лицо, шея, интимная область)</td>
                    <td>5000</td>
                </tr>
                <tr>
                    <td>Удаление липом, атером туловища до 2 см</td>
                    <td>3500</td>
                </tr>
                <tr>
                    <td>Удаление липом, атером туловища 2 - 4 см</td>
                    <td>4500</td>
                </tr>
                <tr>
                    <td>Удаление липом, атером туловища от 4 см</td>
                    <td>5500</td>
                </tr>
                <tr>
                    <td>Удаление липом, атером туловища от 4 см (сложная локализация)</td>
                    <td>7000</td>
                </tr>
                <tr>
                    <td>Удаление липом, атером на голове, лице, шеи, интимная область</td>
                    <td>6000</td>
                </tr>
                <tr>
                    <td>Удаление вросшего ногтя</td>
                    <td>7000</td>
                </tr>
                <tr>
                    <td>Вскрытие паронихия</td>
                    <td>3500</td>
                </tr>
                <tr>
                    <td>Вскрытие панариция</td>
                    <td>4000</td>
                </tr>
                <tr>
                    <td>Иссечение гигромы кисти</td>
                    <td>11000</td>
                </tr>
                <tr>
                    <td>Иссечение гигромы нижней конечности</td>
                    <td>15000</td>
                </tr>
                <tr>
                    <td>Биопсия кожно-мышечного лоскута</td>
                    <td>12000</td>
                </tr>
                <tr>
                    <td>Биопсия новообразований с использованием ЭХВЧ</td>
                    <td>8000</td>
                </tr>
                <tr>
                    <td>Удаление лигатурных свищей</td>
                    <td>6000</td>
                </tr>
                <tr>
                    <td>Обработка ложа матрицы ногтевой пластины</td>
                    <td>3000</td>
                </tr>
                <tr>
                    <td>Иссечение послеоперационного рубца</td>
                    <td>8000</td>
                </tr>
                <tr>
                    <td>Снятие гипса</td>
                    <td>2000</td>
                </tr>
                <tr>
                    <td>Наложение гипсовой повязки</td>
                    <td>4500</td>
                </tr>
                <tr>
                    <td>Удаление блокирующего винта, винта из кости (1шт)</td>
                    <td>6000</td>
                </tr>
                <tr>
                    <td>Удаление накостной пластины (1шт) с ЭХВЧ</td>
                    <td>5000</td>
                </tr>
                <tr>
                    <td>Удаление спиц из кости (1шт)</td>
                    <td>1500</td>
                </tr>
                <tr>
                    <td>Удаление спице-проволочного серкляжа по Веберу</td>
                    <td>25000</td>
                </tr>
                <tr>
                    <td>Демонтаж аппарата Илизарова</td>
                    <td>1000</td>
                </tr>
                <tr>
                    <td>Фиксация-коррекция апарата Илизарова</td>
                    <td>4000</td>
                </tr>
                <tr>
                    <td>Внутрисуставное введение препарата (без стоимости препарата)</td>
                    <td>3000</td>
                </tr>
                <tr>
                    <td>Внутрисуставное введение препарата Дипромета 7 мг/мл</td>
                    <td>4000</td>
                </tr>
                <tr>
                    <td>Внутримышечная инъекция</td>
                    <td>250</td>
                </tr>
                <tr>
                    <td>Иссечение геморроидальных бахромок (1шт) с ЭХВЧ</td>
                    <td>10000</td>
                </tr>
                <tr>
                    <td>Иссечение тромбированного геморроидального узла (1шт) с ЭХВЧ</td>
                    <td>10000</td>
                </tr>
                <tr>
                    <td>Иссечение точечного свища при ЭКХ (эпителиальном копчиковом ходе) с ЭХВЧ</td>
                    <td>12000</td>
                </tr>
                <tr>
                    <td>Иссечение перианальных кондилом с ЭХВЧ + гистологическое исследование</td>
                    <td>15000</td>
                </tr>
                <tr>
                    <td>Удаление фиброзного анального полипа с ЭХВЧ</td>
                    <td>7000</td>
                </tr>
                <tr>
                    <td>PRP Терапия (введение плазмы обогащенной тромбоцитами)</td>
                    <td>10500</td>
                </tr>
                <tr>
                    <th colspan="2" className='text-center'>Анализы INVITRO</th>
                </tr>
                <tr>
                    <td>Забор крови</td>
                    <td>175</td>
                </tr>
                <tr>
                    <td>Комплекс «Обследование» (ОАК, ОАМ, ПТИ, МНО, биохимия: креатинин, мочевина, амилаза, АЛТ, АСТ, билирубин, общий белок,
                    альбумин, глюкоза)</td>
                    <td>2600</td>
                </tr>
                <tr>
                    <td>Комплекс «Госпитализация» (ОАК, ОАМ, ПТИ, МНО, биохимия:
                    креатинин, мочевина, амилаза, АЛТ, АСТ, билирубин, общий белок,
                    альбумин, глюкоза. ВИЧ, сифилис, гепатиты В и С.)</td>
                    <td>4200</td>
                </tr>
                <tr>
                    <td>Общий анализ крови (ОАК)</td>
                    <td>415</td>
                </tr>
                <tr>
                    <td>Общий анализ мочи (ОАМ)</td>
                    <td>260</td>
                </tr>
                <tr>
                    <td>ПТИ, МНО</td>
                    <td>320</td>
                </tr>
                <tr>
                    <th colspan="2" className='text-center'>Биохимия</th>
                </tr>
                <tr>
                    <td>ACT</td>
                    <td>170</td>
                </tr>
                <tr>
                    <td>АЛТ</td>
                    <td>170</td>
                </tr>
                <tr>
                    <td>Альфа-амилаза панкреатическая</td>
                    <td>250</td>
                </tr>
                <tr>
                    <td>Креатинин</td>
                    <td>200</td>
                </tr>
                <tr>
                    <td>Мочевина</td>
                    <td>200</td>
                </tr>
                <tr>
                    <td>Билирубин общий</td>
                    <td>170</td>
                </tr>
                <tr>
                    <td>Билирубин прямой (Билирубин конъюгированный, связанный)</td>
                    <td>200</td>
                </tr>
                <tr>
                    <td>Альбумин</td>
                    <td>260</td>
                </tr>
                <tr>
                    <td>Общий белок</td>
                    <td>200</td>
                </tr>
                <tr>
                    <td>Глюкоза</td>
                    <td>150</td>
                </tr>
                <tr>
                    <td>ПЦР тест на Сovid</td>
                    <td>1200</td>
                </tr>
                <tr>
                    <td>ВИЧ, сифилис, гепатиты В и С</td>
                    <td>1600</td>
                </tr>
                <tr>
                    <td>Гистологическое исследование материала, полученного при хирургических вмешательствах</td>
                    <td>3500</td>
                </tr>
                <tr>
                    <td>Посев раневого отделяемого и тканей на микрофлору, определение чувствительности к антимикробным препаратам</td>
                    <td>2300</td>
                </tr>
            </tbody>
        </Table>
        </Container>
      <Container className='container-prices-buttons mt-5 mb-5 d-flex justify-content-end flex-column gap-3 align-items-end'>
        <Button variant='primary' href='https://disk.yandex.com/i/KfZ4XtSDf2n3Iw'>Посмотреть документ</Button>
        <Button variant='success' href='https://prodoctorov.ru/ekaterinburg/vrach/495857-sabadash/'>Записаться на консультацию</Button>
        <Button href="tel:+79221019112">Позвоните нам!</Button>
      </Container>
    </>
    )
}
